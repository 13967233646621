const Footer = () => {
  return (
    <>
      <footer className="py-3 bg-gray-800 border-t border-gray-600">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-none text-xl font-semibold text-white">
            Random my wheel
          </div>
          <div className="flex-none text-xs text-gray-400">
            Copyright © {new Date().getFullYear()} - v{process.env.REACT_APP_VERSION}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
