import { useState, useEffect } from "react";
import { IStorageData, useAppContext } from "../../../App";

const LoadValuesFromList = () => {
  const {
    values,
    setValues,
    loadValuesFromListPopupVisible,
    setLoadValuesFromListPopupVisible,
  } = useAppContext();
  const [savedLists, setSaveLists] = useState<IStorageData[]>([]);
  const [objectToAdd, setObjectToAdd] = useState<string>('');

  const refreshListFromStorage = () => {
    const localStorageData: string | null = localStorage.getItem("savedValues");
    setSaveLists(JSON.parse(localStorageData || "[]"));
  };

  useEffect(() => {
    refreshListFromStorage();
  }, [loadValuesFromListPopupVisible]);

  if (!loadValuesFromListPopupVisible) {
    return <></>;
  }

  const loadList = (index: number) => {
    const values = savedLists[index]?.values;

    if (values) {
      setValues(values);
      setLoadValuesFromListPopupVisible(false);
    }
  };

  const deleteList = (index: number) => {
    const values = savedLists[index];

    if (values) {
      const newValues = [...savedLists]
      newValues.splice(index, 1);
      localStorage.setItem(
        "savedValues",
        JSON.stringify(newValues)
      );
      setSaveLists(newValues);
    }
  };

  const objectToAddInputChange = ({
    target,
  }: {
    target: HTMLInputElement;
  }) => {
    setObjectToAdd(target.value);
  };

  const addObjectToStorage = () => {
    const objectToAddTrim = objectToAdd.trim();
    if (objectToAddTrim === "") {
      return false;
    }

    const oldLocalData = JSON.parse(localStorage.getItem("savedValues") || "[]");

    localStorage.setItem(
      "savedValues",
      JSON.stringify([...oldLocalData, JSON.parse(objectToAdd)])
    );

    setObjectToAdd('');
    refreshListFromStorage();
  };

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-black/50 p-4 z-50">
        <div className="bg-white p-4 max-w-[450px] w-full">
          <div className="text-2xl mb-4">Load your values</div>
          <div className="flex flex-col gap-2">
            {savedLists.map((list, index) => (
              <div key={index} className="flex items-center justify-between">
                <div>{list.name}</div>
                <div className="flex gap-2">
                  <button
                    className="px-4 py-2 bg-red-600 text-white hover:bg-red-500"
                    onClick={() => {
                      deleteList(index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="px-4 py-2 bg-black text-white hover:bg-gray-800"
                    onClick={() => {
                      loadList(index);
                    }}
                  >
                    Load
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 pt-4 border-t">
            <label htmlFor="object-to-add" className="mb-2 block">
              JSON Object to add in local storage
            </label>
            <div className="flex">
              <input
                type="text"
                id="object-to-add"
                value={objectToAdd}
                onChange={objectToAddInputChange}
                className="border-2 border-black p-2 w-full"
              />
              <button
                className="px-4 py-2 bg-black text-white hover:bg-gray-800"
                onClick={addObjectToStorage}
              >
                Add
              </button>
            </div>
          </div>
          <div className="flex justify-end mt-4 pt-4 border-t">
            <button type="button"
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300"
              onClick={() => {
                setLoadValuesFromListPopupVisible(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoadValuesFromList;
