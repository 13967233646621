import { IValue } from "../../App";

const LastWinners = (props: {lastWinners: IValue[]}) => {
    return <div className='text-white'>
    <div className='text-xl underline mb-2'>Last winners</div>
    <div>
        {[...props.lastWinners].reverse().map((winner, index) => (
        <div key={index} className='[&:first-child_span]:text-4xl [&:first-child_span]:mb-2'>
            <span className='block'>{winner.value}</span>
        </div>))}
    </div>
</div>
};

export default LastWinners;