import { useState, createContext, useContext } from "react";
import "./App.css";
import Header from "./components/layout/Header";
import Content from "./components/layout/Content";
import Footer from "./components/layout/Footer";
import AddValuesToListPopup from "./components/layout/popup/AddValuesToListPopup";
import LoadValuesFromList from "./components/layout/popup/LoadValuesFromList";

export interface IValue {
  value: string;
  color?: string;
}

export interface IStorageData {
  name: string;
  values: IValue[];
}

interface IContext {
  values: IValue[];
  setValues: (values: IValue[]) => void;
  addValuesToListPopupVisible: boolean;
  setAddValuesToListPopupVisible: (addValuesToListPopupVisible: boolean) => void;
  loadValuesFromListPopupVisible: boolean;
  setLoadValuesFromListPopupVisible: (loadValuesFromListPopupVisible: boolean) => void;
}

const AppContext = createContext<IContext>({
  values: [],
  setValues: () => {},
  addValuesToListPopupVisible: false,
  setAddValuesToListPopupVisible: () => {},
  loadValuesFromListPopupVisible: false,
  setLoadValuesFromListPopupVisible: () => {},
});

const App = () => {
  const [values, setValues] = useState<IValue[]>([
    {
      value: "Yes",
      color: '#109e19',
    },
    {
      value: "No",
      color: 'blue',
    },
    {
      value: "Yes",
      color: '#109e19',
    },
    {
      value: "No",
      color: 'purple',
    },
    {
      value: "No",
      color: 'yellow',
    }
  ]);
  const [addValuesToListPopupVisible, setAddValuesToListPopupVisible] = useState<boolean>(false);
  const [loadValuesFromListPopupVisible, setLoadValuesFromListPopupVisible] = useState<boolean>(false);
  const contextValue: IContext = {
    values,
    setValues,
    addValuesToListPopupVisible,
    setAddValuesToListPopupVisible,
    loadValuesFromListPopupVisible,
    setLoadValuesFromListPopupVisible,
  };

  return (
    <div className="App">
      <AppContext.Provider value={contextValue}>
        <Header></Header>
        <Content></Content>
        <Footer></Footer>
        <AddValuesToListPopup></AddValuesToListPopup>
        <LoadValuesFromList></LoadValuesFromList>
      </AppContext.Provider>
    </div>
  );
};

export default App;

export const useAppContext = () => useContext(AppContext);
