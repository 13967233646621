import Wheel from "../Wheel";

const Content = () => {
  return <>
    <div className="px-8 md:px-12">
      <main className="container mx-auto my-8">
        <Wheel></Wheel>
      </main>
    </div>
  </>
};

export default Content;
