import { useAppContext } from "../../App";

const Header = () => {
  const {
    addValuesToListPopupVisible,
    setAddValuesToListPopupVisible,
    loadValuesFromListPopupVisible,
    setLoadValuesFromListPopupVisible
  } = useAppContext();

  const saveValues = () => {
    setAddValuesToListPopupVisible(!addValuesToListPopupVisible);
  }
  const loadValues = () => {
    setLoadValuesFromListPopupVisible(!loadValuesFromListPopupVisible);
  }
  const resetValues = () => {
    localStorage.setItem("savedValues", JSON.stringify([]))
  }

  return <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white text-sm py-4 bg-gray-700">
    <nav className="container mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
        <div className="flex-none text-xl font-semibold text-white">Random my wheel</div>
        <div className="flex flex-row items-center gap-5 mt-5 sm:justify-end sm:mt-0 sm:pl-5">
          <div className="flex items-center [&>.hs-mega-menu-content]:hover:opacity-100 [&>.hs-mega-menu-content]:hover:block relative flex-col sm:flex-row gap-2">
            <button type="button" className="hs-mega-menu-toggle flex items-center w-full font-medium text-gray-100 cursor-pointer py-2">
              Settings
              <svg className="ml-2" width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.96967 8.46967C4.26256 8.17678 4.73744 8.17678 5.03033 8.46967L12 15.4393L18.9697 8.46967C19.2626 8.17678 19.7374 8.17678 20.0303 8.46967C20.3232 8.76256 20.3232 9.23744 20.0303 9.53033L12.5303 17.0303C12.2374 17.3232 11.7626 17.3232 11.4697 17.0303L3.96967 9.53033C3.67678 9.23744 3.67678 8.76256 3.96967 8.46967Z" fill="black"></path>
              </svg>
            </button>
            <div className="hs-mega-menu-content hs-mega-menu-open:opacity-100 opacity-0 top-full right-0 z-10 before:absolute before:-top-5 before:left-0 before:w-full before:h-5 before:cursor-pointer w-full sm:p-2 sm:w-48 sm:transition-all transition-[opacity,margin] sm:absolute sm:shadow-md sm:duration-300 sm:bg-gray-800 hidden" aria-labelledby="hs-mega-menu-basic-dr">     
              <button type="button" onClick={saveValues} className="flex items-center w-full gap-x-3.5 py-2 sm:px-3 text-sm text-white hover:bg-gray-700 focus:ring-2 focus:ring-blue-500">Save values</button>
              <button type="button" onClick={loadValues} className="flex items-center w-full gap-x-3.5 py-2 sm:px-3 text-sm text-white hover:bg-gray-700 focus:ring-2 focus:ring-blue-500">Load</button>
              <button type="button" onClick={resetValues} className="flex items-center w-full gap-x-3.5 py-2 sm:px-3 text-sm text-white hover:bg-gray-700 focus:ring-2 focus:ring-blue-500">Reset</button>
            </div>
          </div>
        </div>
    </nav>
  </header>
};

export default Header;