import { useState } from "react";
import { IStorageData, useAppContext } from "../../../App";

const AddValuesToListPopup = () => {
  const {
    values,
    addValuesToListPopupVisible,
    setAddValuesToListPopupVisible,
  } = useAppContext();
  const [listName, setListName] = useState("");

  if (!addValuesToListPopupVisible) {
    return <></>;
  }

  const saveList = () => {
    const listNameTrim = listName.trim();
    if (listNameTrim === "") {
      return false;
    }

    const oldLocalData = JSON.parse(localStorage.getItem("savedValues") || "[]");
    const alreadyExist = oldLocalData?.find((value: IStorageData) => value.name === listNameTrim);

    if (alreadyExist) {
      alreadyExist.values = values;
      localStorage.setItem(
        "savedValues",
        JSON.stringify([...oldLocalData, alreadyExist])
      );
    } else {
      localStorage.setItem(
        "savedValues",
        JSON.stringify([...oldLocalData, {name: listNameTrim, values}])
      );
    }

    
    setListName("");
    setAddValuesToListPopupVisible(false);
  };

  const editSaveListInputChange = ({
    target,
  }: {
    target: HTMLInputElement;
  }) => {
    setListName(target.value);
  };

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center bg-black/50 p-4 z-50">
        <div className="bg-white p-4 max-w-[450px] w-full">
          <div className="text-2xl mb-4">Save your values</div>
          <div>
            <div className="mb-4">
              <label htmlFor="list-name" className="mb-2 block">
                List name
              </label>
              <input
                type="text"
                id="list-name"
                value={listName}
                onChange={editSaveListInputChange}
                className="border-2 border-black p-2 w-full"
              />
            </div>
            <div className="flex justify-between mt-4 pt-4 border-t">
              <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300"
                onClick={() => {
                  setAddValuesToListPopupVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-black text-white hover:bg-gray-800"
                onClick={saveList}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddValuesToListPopup;
