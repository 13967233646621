import { useState } from "react";
import { IValue, useAppContext } from "../App";
import LastWinners from "./wheel/LastWinners";
import ValuesForm from "./wheel/ValuesForm";

interface IWheelState {
  spinDelay: number;
  spinTime: number;
  mediaPlayer: HTMLAudioElement;
}

const Wheel = () => {
  const { values, setValues } = useAppContext()

  // Functions
  const spin = () => {
    const angle = 360 / values.length;
    const currentRotationAmount = generateRandomIntegerInRange(8000, 12000);
    const visualRotationAmount = currentRotationAmount - angle / 2;
    const currentWinner = parseInt(
      ((currentRotationAmount % 360) / angle).toString()
    );

    // Updating states
    setRotationAmount(visualRotationAmount);
    setWheelStatus("spinning");

    playAudio("/audio/spin.mp3");
    setTimeout(() => {
      stopAudio();
      afterSpinEvent(visualRotationAmount, currentWinner);
    }, (wheelState.spinTime + wheelState.spinDelay) * 1000);
    setTimeout(() => {
      document.documentElement.classList.remove("zoom-out");
      document.documentElement.classList.add("zoom-in");
      new Audio("/audio/spin-suspense.mp3").play();
    }, (wheelState.spinTime * 0.55 + wheelState.spinDelay) * 1000);
  };

  const afterSpinEvent = (rotation: number, winner: number) => {
    document.documentElement.classList.remove("zoom-in");
    document.documentElement.classList.add("zoom-out");
    
    // Change wheel status
    setWheelStatus("default");
    setRotationAmount(rotation % 360);
    setLastRotation(rotation % 360);

    // Play sound
    if (values[winner].value === "Math") {
      playAudio("/audio/spin-math.mp3");
      const img = document.createElement('img');
      img.src = '/images/think.gif';
      img.id = 'meme';
      img.classList.add('meme');
      document.querySelector('body')?.append(img);
      setTimeout(() => {
        document.querySelector('#meme')?.remove();
      }, 6000);
    } else if (values[winner].value === "Kevin") {
      playAudio("/audio/spin-kevin.mp3", 30);
      const img = document.createElement('img');
      img.src = '/images/bruh.png';
      img.id = 'meme';
      img.classList.add('shaking');
      img.classList.add('meme');
      document.querySelector('body')?.append(img);
      setTimeout(() => {
        document.querySelector('#meme')?.remove();
      }, 7500);
    } else {
      playAudio("/audio/spin-end.mp3", 25);
    }

    // Add winner to list
    addLastWinner(winner);
  };

  const playAudio = (fileName: string, volume?: number) => {
    if (!volume) {
      volume = 50;
    }

    wheelState.mediaPlayer.src = fileName;
    wheelState.mediaPlayer.volume = volume / 100;
    wheelState.mediaPlayer.load();
    wheelState.mediaPlayer.play();
  };

  const stopAudio = () => {
    wheelState.mediaPlayer.src = "";
  };

  const addValue = (value: string) => {
    var namesCopy = [...values];
    namesCopy.push({
      value,
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    });
    setValues(namesCopy);
  };

  const removeValue = (index: number) => {
    var namesCopy = [...values];
    namesCopy.splice(index, 1);
    setValues(namesCopy);
  };

  const addLastWinner = (winner: number) => {
    var namesClone = [...lastWinners];
    namesClone.push(values[winner]);
    setLastWinners(namesClone);
  };

  const generateRandomIntegerInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // States
  const [lastWinners, setLastWinners] = useState<IValue[]>([]);
  const [wheelStatus, setWheelStatus] = useState<string>("");
  const [rotationAmount, setRotationAmount] = useState<number>(0);
  const [lastRotation, setLastRotation] = useState<number>(0);
  const [wheelState] = useState<IWheelState>({
    spinDelay: 2.75,
    spinTime: generateRandomIntegerInRange(15, 20),
    mediaPlayer: new Audio("/audio/spin.mp3")
  });

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="wheel-container md:w-[40%] flex items-center justify-center relative">
        <div className="text-5xl bold absolute top-1/2 left-0 translate-y-[-50%] z-10">
          <svg
            height="48"
            width="48"
            viewBox="0 0 64 64"
            className="z-10 relative w-8 md:w-12 left-[-28px] md:left-[-40px]"
          >
            <polygon
              points="0 10,0 50,64 32"
              style={{fill: `rgb(255, 255, 255, 1)`, stroke: `black`, strokeWidth: 1,}}
            />
          </svg>
        </div>
        <div className="overflow-hidden w-full">
          <div className={`wheel w-full h-full bg-black aspect-square ${wheelStatus === "spinning" ? `spinning` : ""}`} style={{
            "--rotation": `${rotationAmount + 90}deg`,
            "--last-rotation": `${lastRotation + 90}deg`,
            "--rotation-duration": `${wheelState.spinTime}s`,
            "--rotation-delay": `${wheelState.spinDelay}s`,
          } as React.CSSProperties}>
            {values.map((data, index) => (
              <div
                key={index}
                className="wheel-triangle pb-4 flex items-end justify-center text-white absolute h-full top-0 left-1/2 translate-x-[-50%] w-full"
                style={{
                  background: `${data.color}`,
                  transform: `translate(-50%, 100%) rotate(-${(360 / values.length) * index}deg)`,
                  "--width": `${(Math.tan((360 / values.length / 2) * (Math.PI / 180)) * (500 / 2)) * 2 * 100 / 500}%`
                } as React.CSSProperties}
              >
                <span className="wheel-name text-3xl drop-shadow-lg shadow-black">
                  {data.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="md:w-[60%]">
        <div className="flex flex-col gap-4 mb-8">
          <button
            onClick={spin}
            disabled={wheelStatus === "spinning"}
            className="bg-black text-white p-2"
          >
            {wheelStatus === "spinning" ? "Spinning" : "Spin"}
          </button>
        </div>
        <div className="grid md:grid-cols-8 gap-y-12 md:gap-x-4">
          <div className="md:col-span-3">
            <LastWinners lastWinners={lastWinners}></LastWinners>
          </div>
          <div className="md:col-span-5">
            <ValuesForm
              values={values}
              setValues={setValues}
              addValue={addValue}
              removeValue={removeValue}
            ></ValuesForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wheel;