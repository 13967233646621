import React, { useState } from "react";
import { IValue } from "../../App";

const ValuesForm = (props: {values: IValue[], setValues: (values: IValue[]) => void, addValue: (value: string) => void, removeValue: (index: number) => void}) => {
    // State
    const [newValueInput, setNewValueInput] = useState('');

    // Functions
    const newValueInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewValueInput(event.target.value);
    };

    const editValueInputChange = ({ target }: {target: HTMLInputElement}) => {
        const value = target.value;
        const index = Number(target.dataset.index) || 0;

        var valuesCopy = [...props.values];
        valuesCopy[index].value = value;

        props.setValues(valuesCopy);
    };

    const addValueClick = () => {
        props.addValue(newValueInput);
        setNewValueInput('');
    };

    const removeValueClick = (index: number) => {
        props.removeValue(index);
    };

    let test = props.values.map((value, index) => {
        return (
            <div key={index} className="flex items-center mb-2">
                <input
                name={index.toString()}
                data-index={index}
                placeholder={index.toString()}
                value={value.value}
                onChange={editValueInputChange}
                className="border-2 border-black p-2 w-full"
                />
                <button type='button' className='ml-2 px-4 py-2 bg-gray-200 hover:bg-gray-300' onClick={() => {removeValueClick(index)}}>Delete</button>
            </div>
        );
    });

  return (
    <div>
      <div>
        <div className="mb-2 flex items-center">
          <input
            name="new-name"
            placeholder={"Name"}
            value={newValueInput}
            onChange={newValueInputChange}
            className="border-2 border-black p-2 w-full"
          />
          <button
            type="button"
            className="ml-2 px-4 py-2 bg-gray-200 hover:bg-gray-300"
            onClick={addValueClick}
          >
            Add
          </button>
        </div>
      </div>
      <form className="xl:grid grid-cols-2 gap-x-8">{test}</form>
    </div>
  );
};

export default ValuesForm;
